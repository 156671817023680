/*!

=========================================================
* Argon Design System React - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import { blog_url } from "constants/global";
// reactstrap components
import { Button, Container, Row, Col,  Badge,
  Card,
  CardBody,
  FormGroup,
  Input,
  UncontrolledAlert,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import validator from 'validator';
import {firebaseTest,publishMessage} from "../../firebase";

class LandingPage extends React.Component {
  state = {
    email : "",
    name : "",
    message : "",
    isEmail : true
  };
  clearFields = ()=>{
    this.setState({
      email : "",
    name : "",
    message : ""
    })
  }
  render() {
    return (
      <>
        <div className="position-relative">
          <section className="section section-hero section-shaped">
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/uptechniques_high.png")}
                      style={{ width: "250px" }}
                    />
                    <p className="lead text-white">
                      The Swift army knife for your digital needs.
                    </p>
                    <div className="btn-wrapper mt-8">
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="fa fa-wrench" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Tools Available
                          </h6>
                          <p className="description mt-3">
                           We provide quick and easy solutions to common online tasks.
                           With intuitive interfaces that make it easy for anyone, regardless of technical expertise, to use them
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              Converter
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Json Beautifier
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              Url Shortner
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="https://tools.uptechniques.com"
                            
                          >
                            Go To Tools
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="fa fa-rss" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Read Blogs
                          </h6>
                          <p className="description mt-3">
                          Welcome to our blog, where we share our thoughts and insights on a variety of topics.So take a look around, read some of our posts,
                           and don't hesitate to share your thoughts.
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              Technology
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              Web Tools
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              Tips
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href={blog_url}
                            
                          >
                            Click To Read
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="fa fa-book" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Read Books
                          </h6>
                          <p className="description mt-3">
                          We showcase some of the best and most thought-provoking books on a variety of topics. 
                          We've got you covered with a handpicked selection of books that we believe will enrich your life and expand your mind.
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              Fiction
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Short Story
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              Non Fiction
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            disabled = {true}
                            onClick={(e) => e.preventDefault()}
                          >
                            Coming Soon
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Contact Us</h4>
                      <p className="mt-0">
                        Your message is important to us.
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            type="text"
                            value = {this.state.name}
                            onChange = {(event)=>{
                              this.setState({
                                name : event.target.value
                              })
                            }}
                            onFocus={(e) =>
                              this.setState({ nameFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ nameFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            value = {this.state.email}
                            onChange = {(event)=>{
                              this.setState({
                                email : event.target.value
                              })
                            }}
                            onFocus={(e) =>
                              this.setState({ emailFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ emailFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          value = {this.state.message}
                          placeholder="Type a message..."
                          rows="4"
                          onChange = {(event)=>{
                            this.setState({
                              message : event.target.value
                            })
                    }}
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          onClick = {()=>{
                            console.log("Email",this.state.email)
                            if(validator.isEmail(this.state.email)){  
                            publishMessage(this.state.name,this.state.email,this.state.message)
                            this.clearFields();
                            }else{
                              console.log("not a valid mail")
                              this.setState({
                                isEmail:false
                              })
                            }
                          
                          }}
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                        >
                          Send Message
                        </Button>
                      </div>
                      {!this.state.isEmail&&<UncontrolledAlert style={{marginTop:'10px'}} color="danger" fade={false}>
                        <span className="alert-inner--icon">
                        <i className="fa fa-exclamation-triangle" />
                        </span>{" "}
                      <span className="alert-inner--text">
                      Sorry, the email you entered is not valid.
                      </span>
                      </UncontrolledAlert>}
                    
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>

        </div>
      </>
    );
  }
}

export default LandingPage;
